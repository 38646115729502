import React, { useState } from "react";
import "./styles/main.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "../node_modules/bootstrap-select/dist/js/bootstrap-select";
import { useStickyHeader } from "react-use-sticky-header";
import Ads from "./components/Ads";
import Logo from "./assets/logo.jpg";
import Home from "./components/Home";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";

function App() {
  const [setHeaderRef] = useStickyHeader(50, {
    headerDetached: "stricked-menu",
  });



  const Layout = ({ children }) => (
    <div>

      <span className="anchor" id="home"></span>
      <div className="header" ref={setHeaderRef}>
        <Header />
      </div>

      {children}
      <Footer />
    </div>
  );

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Layout>
            <Route exact path="/" component={Home} />
            {/*<Route path="/Ads">*/}
            {/*  <Ads />*/}
            {/*</Route>*/}
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
