import React from 'react';
function Index (){
    return(
        <>
       <div className="container mt-4 mb-4 ads_block">
           <div className="row">
               <div className="col-sm-12">
               <select className="form-control" >
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
    <option value="fiat">Fiat</option>
    <option value="audi">Audi</option>
  </select>
               </div>
               <div className="col-sm-12" style={{columnCount:2, columnGap:20, marginTop:50}}>
               <img src="https://via.placeholder.com/500"/>
               <img src="https://via.placeholder.com/500x600"/>
               <img src="https://via.placeholder.com/500x600"/>
               <img src="https://via.placeholder.com/500x600"/>
               <img src="https://via.placeholder.com/250x300"/>
               <img src="https://via.placeholder.com/250x300"/>
               <img src="https://via.placeholder.com/200x600"/>
               <img src="https://via.placeholder.com/200x600"/>
               </div>
           </div>
       </div>
        </>
    )
}
export default Index;