import React from 'react';
import Banner from '../components/Banner';
import About from '../components/About';
import Services from '../components/Services';
import Schedule from '../components/Schedule';
import Staff from '../components/Staff';
import Testimonials from '../components/Testimonials';
import { useStickyHeader } from "react-use-sticky-header";

function Index(){
    const [gaddRef] = useStickyHeader(550, {
        headerDetached: "add-fixed",
      });
    return(
        <>
        <div className="adds" ref={gaddRef}>
        <div
          style={{
            width: 200,
            height: 600,
            position: "absolute",
            top: 650,
            left: 10,
          }}
        >
          <img src="https://via.placeholder.com/200x600" />
        </div>
      </div>
        <div>

          <Banner/>
          <About/>
          <Services/>
          <div className="container pt-5 adds">
              <div className="row">
                  <div className="col-sm-12">
                  <img src="https://via.placeholder.com/970x120"/>
                  </div>
              </div>
          </div>
          <Schedule/>
          <Testimonials/>

          <Staff/>
          <div className="container pt-5 pb-5 adds">
              <div className="row">
                  <div className="col-sm-12">
                  <img src="https://via.placeholder.com/970x120"/>
                  </div>
              </div>
          </div>
          </div>
        </>
    )
}

export default Index;
