import React from "react";
import Reveal from 'react-reveal/Reveal';
import ServicesList from "./ServicesList";

function index() {
  return (
    <>
      <section className="service-one thm-gray-bg anchor" id="services">
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title">Our Services</h2>
            <p className="block-title__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt uttom <br /> labore et dolore magna
              aliqua. Quis ipsum suspendisse ultrices gravida.
            </p>
          </div>
          <ServicesList/>
        </div>
      </section>
    </>
  );
}

export default index;
