import React from "react";
import Reveal from 'react-reveal/Reveal';

const data = [
  { id: 1, title: "General consultations" },
  { id: 2, title: "Teenage health" },
  { id: 3, title: "Vaccinations" },
  { id: 4, title: "Skin cancer checks" },
  { id: 5, title: "Children’s health" },
  { id: 6, title: "Immunisations" },
  { id: 7, title: "Travel medicine" },
  { id: 8, title: "Chronic Disease Management" },
  { id: 9, title: "Mental Health Care Plans" },
  { id: 10, title: "Women’s health" },
  { id: 11, title: "Pap smears" },
  { id: 12, title: "Sexual health" },
  { id: 13, title: "Erectile dysfunction" },
  { id: 14, title: "Pregnancy Tests" },
  { id: 15, title: "Breast checks" },
  { id: 16, title: "Pregnancy Care" },
  { id: 17, title: "Fertility and contraception" },
  { id: 18, title: "Menopause" },
  { id: 19, title: "Men’s health" },
  { id: 20, title: "Prostate checks" },
  {
    id: 21,
    title: "Weight issues and nutrition, heart health and diabetes prevention",
  },
];
function index({isPopup=false}) {
  return (
    <>
          <div className="row">
            {data.map((item) => (
              <div className={isPopup?"col-lg-6 col-md-6 col-sm-12":"col-lg-3 col-md-6 col-sm-12"} key={item.id}>
                <ul className="about-one__features">
                <Reveal effect="fadeInUp">
                <li className="about-one__features-item">{item.title}</li>
                </Reveal>

                </ul>
              </div>
            ))}
          </div>
    </>
  );
}

export default index;
