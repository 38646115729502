import React from 'react';

function Index (){
    return(
        <>
        <form action="#" className="appointment-two__form contact-from-validated wow fadeInDown" data-wow-duration="2s">
                            <div className="appointment-two__form-top">
                                <h3 className="appointment-two__form-title">Get an Appointment</h3>
                            </div>
                            <div className="appointment-two__form-bottom">
                                <div className="appointment-two__form-field">
                                    <input type="text" name="name" placeholder="Enter your name"/>
                                    <i className="appointment-two__form-field-icon material-icons">person</i>
                                </div>
                                <div className="appointment-two__form-field">
                                    <input type="text" name="name" placeholder="Enter your phone"/>
                                    <i className="appointment-two__form-field-icon material-icons">phone</i>
                                </div>
                                <div className="appointment-two__form-field">
                                    <input type="text" name="name" placeholder="Appointment Date" className="datepicker"/>
                                    <i className="appointment-two__form-field-icon material-icons">date_range</i>
                                </div>
                                <div className="appointment-two__form-field">
                                    <select className="selectpicker">
                                        <option>Choose Department</option>
                                        <option>Choose Department</option>
                                        <option>Choose Department</option>
                                        <option>Choose Department</option>
                                        <option>Choose Department</option>
                                    </select>
                                </div>
                                <div className="appointment-two__form-field">
                                    <select className="selectpicker">
                                        <option>Select Doctor</option>
                                        <option>Select Doctor</option>
                                        <option>Select Doctor</option>
                                        <option>Select Doctor</option>
                                        <option>Select Doctor</option>
                                    </select>
                                </div>
                                <div className="appointment-two__form-field">
                                    <button type="submit" className="appointment-two__form-btn">Request Appointment</button>
                                </div>
                            </div>
                        </form>
        </>
    )
}

export default Index;