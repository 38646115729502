import React, { useState } from "react";
import Aboutimg from "../assets/resources/about.jpg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Fade from 'react-reveal/Fade';
import Services from "./Services";
import ServicesList from "./ServicesList";

function Index() {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <section className="about-one anchor" id="about">
        <div className="container">
          <div className="row">
          <Fade left>
          <div className="col-lg-8">
              <div className="">
                <h3 className="about-one__title">About US</h3>
                <p className="about-one__tag-line">
                  Servicing the Community for Over 40 Years
                </p>
                <p className="about-one__text">
                  At Strathfield Plaza Family Medical Practice we have provided
                  the highest standard of patient care to our community for the
                  last four decades and remains committed to promoting wellness
                  and disease prevention to all.{" "}
                </p>
                <p className="about-one__text">
                  We understand some of your health concerns can leave you
                  feeling uncomfortable and even slightly embarrassed. We have
                  female doctors who specialise in woman’s health and ante natal
                  Services, young doctors who specialise in teenage health and
                  experienced doctors who treat Allergy and Skin Disorders.{" "}
                </p>
                <p className="about-one__text">
                  Our skilled doctors are responsible for your medical needs and
                  work to offer a wide range of general practice services,
                  including:
                </p>

                <a
                  onClick={onOpenModal}
                  href="javascript:avoid(0)"
                  className="thm-btn about-one__btn"
                >
                  Read More
                </a>
              </div>
            </div>
          </Fade>
          <Fade right>
          <div className="col-lg-4">
              <div className="wow fadeInRight" data-wow-duration="1s">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="about-one__image">
                      <img src={Aboutimg} alt="Awesome Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>


          </div>
        </div>
      </section>

      <Modal open={open} onClose={onCloseModal} center>
        <div style={{ padding: 30 }}>
          <h3 className="about-one__title">About US</h3>
          <p className="about-one__tag-line">
            Servicing the Community for Over 40 Years
          </p>
          <p className="about-one__text">
            At Strathfield Plaza Family Medical Practice we have provided the
            highest standard of patient care to our community for the last four
            decades and remains committed to promoting wellness and disease
            prevention to all.{" "}
          </p>
          <p className="about-one__text">
            We understand some of your health concerns can leave you feeling
            uncomfortable and even slightly embarrassed. We have female doctors
            who specialise in woman’s health and ante natal Services, young
            doctors who specialise in teenage health and experienced doctors who
            treat Allergy and Skin Disorders.{" "}
          </p>
          <p className="about-one__text">
            Our skilled doctors are responsible for your medical needs and work
            to offer a wide range of general practice services, including:
          </p>
          <ServicesList isPopup={true}/>
        </div>
      </Modal>
    </>
  );
}

export default Index;
