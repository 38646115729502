import React from 'react';
import AppointmentForm from './AppointmentForm';
import Slide from 'react-reveal/Reveal';

function index (){
    return(
        <>
           <section className="appointment-table anchor " id="Schedule">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-12">
                        <div className="block-title text-center">
                            <h2 className="block-title__title">Department’s Regular Schedule</h2>
                            <p className="block-title__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut <br/> labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                    <Slide left>
                    <div className="col-lg-8">
                       
                       <div className="appointment-table__content ">
                           <div className="table-responsive">
                               <table className="table">
                                   <thead>
                                       <tr>
                                           <th scope="col">Days</th>
                                           <th scope="col">Time</th>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       <tr>
                                           <td>Monday</td>
                                           <td>9:00 AM - 7:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Tuesday</td>
                                           <td>9:00 AM - 7:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Wednesday</td>
                                           <td>9:00 AM - 7:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Thursday</td>
                                           <td>9:00 AM - 7:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Friday</td>
                                           <td>9:00 AM - 7:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Saturday</td>
                                           <td>9:00 AM - 2:00 PM</td>
                                       </tr>
                                       <tr>
                                           <td>Sunday</td>
                                           <td>Closed</td>
                                       </tr>
                                       <tr>
                                           <td>Public Holidays</td>
                                           <td>Closed</td>
                                       </tr>
                                       
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>
                    </Slide>
                    <Slide right>
                    <div className="col-lg-4">
                        <AppointmentForm/>
                    </div>
                    </Slide>
                   
                </div>
            </div>
        </section>
        </>
    )
}

export default index;
