import React from "react";
import quote from "../assets/resources/testi-qoute-1-1.png";
import Slider from "react-slick";
import Slide from 'react-reveal/Slide';

const data = [
  {
    id: 1,
    name: "Parthenia Woodington",
    designation: "Dental Clinic",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing eseiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: "https://i.pravatar.cc/400?img=68",
  },
  {
    id: 2,
    name: "Parthenia Woodington",
    designation: "Dental Clinic",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing eseiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: "https://i.pravatar.cc/400?img=68",
  },
  {
    id: 3,
    name: "Parthenia Woodington",
    designation: "Dental Clinic",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing eseiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: "https://i.pravatar.cc/400?img=68",
  },
];
function index() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 500,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonials-one anchor" id="testimonials">
        <div className="testimonials-one__background-image"></div>
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title block-title__light-color">
              What Patients Say About Us
            </h2>
            <p className="block-title__text block-title__light-color">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt uttom <br /> labore et dolore magna
              aliqua. Quis ipsum suspendisse ultrices gravida.
            </p>
          </div>
          <div className="testimonials-one__carousel owl-carousel owl-theme">
            <Slider {...settings}>
              {data.map((item) => (
                 <Slide bottom>
                    <div className="item" key={item.id}>
                  <div className="testimonials-one__single">
                    <div className="testimonials-one__icon">
                      <div className="testimonials-one__qoute">
                        <img src={quote} />
                      </div>
                      <div className="inner-block">
                        <img src={item.img} />
                      </div>
                    </div>
                    <div className="testimonials-one__content">
                      <h3 className="testimonials-one__title">{item.name}</h3>
                      <span className="testimonials-one__designation">
                        {item.designation}
                      </span>
                      <p className="testimonials-one__text">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                 </Slide>
                
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;
