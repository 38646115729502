import React from 'react';
import Slide from 'react-reveal/Slide';
import { useStickyHeader } from "react-use-sticky-header";
import Slider from "react-slick";
import virusIcon from '../assets/coronavirus.svg'

function Index (){
    const [setHeaderRef] = useStickyHeader(400, {
        headerDetached: 'sticky-scroll-text'
      });
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
      };
    return(
        <>


             <div className="banner-wrapper">
            <Slider {...settings} className="main-banner">
                {/*<div className="static-banner-one">*/}
                {/*   <img src={slide2}/>*/}
                {/*</div>*/}
                <div className="static-banner-one">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-lg-12 text-center">
                                <Slide top>
                                    <h3 className="banner-one__title banner-one__light-color">Strathfield Plaza <strong>Family</strong> Medical Practice  </h3>
                                </Slide>
                                <Slide bottom>
                                    <p className="banner-one__text banner-one__light-color">90% of patients would recommend this practice, based on 176 reviews within the last 12 months.  </p>
                                </Slide>



                            </div>

                        </div>
                    </div>
                </div>
                <div className="static-banner-one">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-lg-12 text-center">
                            <Slide top>
                            <h3 className="banner-one__title banner-one__light-color">Strathfield Plaza <strong>Family</strong> Medical Practice  </h3>
                            </Slide>
                            <Slide bottom>
                            <p className="banner-one__text banner-one__light-color">Let us keep you healthy </p>
                            </Slide>
                            </div>

                        </div>
                    </div>
                </div>
                </Slider>
                <a className="add-right" href="#" ref={setHeaderRef}>
                <img src={virusIcon}/> <span>  COVID <br/> vaccination</span>
                </a>




        </div>

        </>
    )
}

export default Index;
