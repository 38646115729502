import React from 'react';
import Team1 from '../assets/team/team1.jpg';
import Team2 from '../assets/team/team2.jpg';
import Team3 from '../assets/team/team3.jpg';
import Team4 from '../assets/team/team4.jpg';
import Flip from 'react-reveal/Flip';

function index (){
    return(
        <>
              <section className="team-one team-one__home-two anchor" id="staff">
            <div className="container">
                <div className="block-title text-center">
                    <h2 className="block-title__title"> Our Staff</h2>
                    <p className="block-title__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt uttom <br/> labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
                <div className="row low-gutters">
                <Flip left>
                <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="team-one__single wow fadeInDown" data-wow-duration="3s">
                            <div className="team-one__image">
                                <img src={Team1} alt="Awesome Image" />
                            </div>
                            <div className="team-one__content">
                                <h3 className="team-one__title"><a href="#">Dr Piyush Sinha</a></h3>
                                <p className="team-one__speciality">General Practice</p>
                            </div>
                            <div className="team-one__hover-content">
                                <a className="team-one__hvr-link" href="#"><i className="material-icons">phone</i></a>
                                <a href="#" className="team-one__hvr-link"><i className="material-icons">email</i></a>
                            </div>
                        </div>
                    </div>
                </Flip>
                <Flip left>
                <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="team-one__single wow fadeInDown" data-wow-duration="3s">
                            <div className="team-one__image">
                                <img src={Team2} alt="Awesome Image" />
                            </div>
                            <div className="team-one__content">
                                <h3 className="team-one__title"><a href="#"> Dr Umesh Kamkolkar </a></h3>
                                <p className="team-one__speciality">General Practice</p>
                            </div>
                            <div className="team-one__hover-content">
                                <a className="team-one__hvr-link" href="#"><i className="material-icons">phone</i></a>
                                <a href="#" className="team-one__hvr-link"><i className="material-icons">email</i></a>
                            </div>
                        </div>
                    </div>
                </Flip>
                <Flip left>
                <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="team-one__single wow fadeInDown" data-wow-duration="3s">
                            <div className="team-one__image">
                                <img src={Team3} alt="Awesome Image" />
                            </div>
                            <div className="team-one__content">
                                <h3 className="team-one__title"><a href="#">Dr Prabhat Sinha</a></h3>
                                <p className="team-one__speciality">General Practice</p>
                            </div>
                            <div className="team-one__hover-content">
                                <a className="team-one__hvr-link" href="#"><i className="material-icons">phone</i></a>
                                <a href="#" className="team-one__hvr-link"><i className="material-icons">email</i></a>
                            </div>
                        </div>
                    </div>
                </Flip>
                <Flip left>
                <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="team-one__single wow fadeInDown" data-wow-duration="3s">
                            <div className="team-one__image">
                                <img src={Team4} alt="Awesome Image" />
                            </div>
                            <div className="team-one__content">
                                <h3 className="team-one__title"><a href="#">Dr Piyush Sinha</a></h3>
                                <p className="team-one__speciality">General Practice</p>
                            </div>
                            <div className="team-one__hover-content">
                                <a className="team-one__hvr-link" href="#"><i className="material-icons">phone</i></a>
                                <a href="#" className="team-one__hvr-link"><i className="material-icons">email</i></a>
                            </div>
                        </div>
                    </div>
                </Flip>
                    
                   
                   
                   
                </div>
            </div>
        </section>
        </>
    )
}

export default index;