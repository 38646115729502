import React, {useState, useEffect} from 'react';
import Logo from '../assets/logo.jpg';
import { useStickyHeader } from "react-use-sticky-header";
import {BrowserRouter, Route, Switch, Link} from "react-router-dom";

function Index (){


      const [isActive, setActive] = useState(true);
    const toStart=()=>{
        window.scrollTo(0, 0);
    }
    return(
        <div className="header"  >

       <section className="topbar-one" >
            <div className="container">
                <ul className="topbar-one__contact">
                    <li className="topbar-one__contact-item"><a href="mailto:help@Strathfield Plaza Family Medical Practice.com"><i
                                className="material-icons">email</i>help@strathfieldplazafamilymedicalpractice.com</a></li>
                    <li className="topbar-one__contact-item"><a href="callto:(02)97469299"><i
                                className="material-icons">local_phone</i>(02)97469299</a></li>
                </ul>
                <div className="topbar-one__buttons">
                    <a href="#Schedule"  className="topbar-one__btn">Get Appointment</a>
                </div>
            </div>
        </section>
        <header className="site-header header-one">
            <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky" id="navigation">
                <div className="container clearfix">
                    <div className="logo-box clearfix">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="main-logo" alt="Logo" onClick={toStart}/>
                        </Link>
                        <button className="menu-toggler" data-target="main-navigation" onClick={() => setActive(!isActive) }>
                            <span className="fa fa-bars"></span>
                        </button>
                    </div>
                    <div className={`main-navigation navbar-fixed-top ${isActive ? "" : 'open'}`} onClick={() => setActive(!isActive) }>
                        <ul className=" navigation-box nav navbar-nav">
                            <li className="active" data-anchor="home" onClick={toStart}><Link to="/">Home</Link></li>
                            <li data-anchor="about"><a href="#about">ABOUT US</a></li>
                            <li data-anchor="services"><a href="#services">Our Services </a></li>
                            <li data-anchor="Schedule"><a href="#Schedule">Our Schedule</a></li>
                            <li data-anchor="testimonials"><a href="#testimonials">testimonials</a></li>
                            <li data-anchor="staff"><a href="#staff">Our staff</a></li>
                            <li data-anchor="contact"><a href="#contact">CONTACT</a></li>
                            {/*<li data-anchor="contact"><Link to="/Ads">Ads</Link></li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        </div>
    )
}

export default Index;
