import React from 'react';
import Slide from 'react-reveal/Slide';

function index (){
    return(
        <>
             <section className="cta-one" >
            <div className="container-fluid">
                <div className="row no-gutters">
                <Slide left>
                <div className="col-lg-6 cta-one__left cta-one__content-wrap thm-gray-bg" >
                        <div className=" cta-one__content">
                            <div className="contact-one__info">
                               
                                <h3 className="contact-one__info__title">Contact Detail</h3>
                                <p className="contact-one__info__text">Strathfield Plaza Shop 20, <br/> Strathfield Plaza / 11 The Boulevard Strathfield, <br/> NSW 2135
                                </p>
                                <p className="contact-one__info__text"><span>Call us:</span> <br/> (02)97469299</p>
                                <p className="contact-one__info__text"><span>Mail us:</span> <br/> help@strathfieldplazafamilymedicalpractice.com</p>
                                <div className="contact-one__info__social">
                                    <a href="#"><i className="fa fa-facebook-f"></i></a>
                                    <a href="#"><i className="fa fa-twitter"></i></a>
                                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fa fa-youtube-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
        </Slide>
        <Slide right>
        <div className="col-lg-6 cta-one__right cta-one__content-wrap thm-gray-bg">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6625.296885451248!2d151.093148!3d-33.87295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e9f187d64a9b082!2sStrathfield%20Plaza!5e0!3m2!1sen!2sau!4v1612062797849!5m2!1sen!2sau" width="100%" height="100%" aria-hidden="false"></iframe>
                    </div>
        </Slide>
                   
                   
                </div>
            </div>
        </section>
        <footer className="footer-one anchor" id="contact">
          
           
            <div className="footer-one__bottom">
                <div className="container justify-content-center">
                    <p className="footer-one__copy-text text-center">&copy; 2021 Strathfieldplazafamilymedicalpractice | All Right Reserved</p>
                   
                </div>
            </div>
        </footer>
        </>
    )
}

export default index;